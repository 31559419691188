import React, { useEffect } from "react";

function BolsaFamilia() {
  useEffect(() => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.appcore.bolsafamilia";
  }, []);

  return <div>Redirecionando para o Google Play...</div>;
}

export default BolsaFamilia;
