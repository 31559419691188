import Navbar from "../components/Navbar";
import styles from "../screens/PoliticaPrivacidade.module.css";
import React from "react";

const PoliticaPrivacidade = () => {
  return (
    <>
      <Navbar />
      <div className={styles.containerPolitica}>
        <h2>
          Política de Privacidade do Aplicativo de Consulta ao Bolsa Família
        </h2>
        <p>
          Esta Política de Privacidade descreve como o aplicativo Bolsa Família
          Consulta coleta, usa e protege as informações pessoais fornecidas
          pelos usuários ao utilizar o aplicativo. A privacidade dos nossos
          usuários é de extrema importância para nós, e estamos empenhados em
          proteger e respeitar seus dados pessoais.
        </p>
        <h2>CT App Core</h2>
        <p>E-mail de Suporte: ctappcore@gmail.com</p>
        <h2>Coleta de Informações:</h2>
        <p>
          Ao utilizar o aplicativo Bolsa Família Consulta, não há nenhuma coleta
          de informações pessoais do usuário.
        </p>
        <h2>Uso das Informações:</h2>
        <p>
          As informações pessoais digitadas serão utilizadas exclusivamente para
          os seguintes propósitos:
        </p>
        <p>
          Consulta ao Bolsa Família: O NIS informado pelo usuário será usado
          para realizar a consulta à API do Portal da Transparência referente ao
          Bolsa Família e fornecer os resultados da consulta ao usuário, mas não
          é armazenado em nenhum local dos desenvolvedores ou de terceiros.
        </p>
        <p>
          O aplicativo armazena exclusivamente o NIS (Número de Identificação
          Social) no dispositivo do usuário, com o propósito de aprimorar a
          experiência de uso. Essa medida visa evitar que o usuário tenha que
          digitar o NIS repetidamente, simplificando o acesso ao aplicativo. E
          ainda assim, o usuário consegue apagar clicando no botão Sair.
        </p>
        <p>
          É importante destacar que a API utilizada para a obtenção dos dados do
          Portal da Transparência é de propriedade exclusiva do Governo Federal.
          Apenas os dados necessários para a funcionalidade do aplicativo serão
          acessados por meio dessa API.
        </p>
        <h2>Proteção das Informações:</h2>
        <p>
          Tomamos medidas adequadas para garantir a segurança das informações
          pessoais fornecidas pelos usuários. Implementamos medidas técnicas e
          organizacionais para proteger contra acesso não autorizado, alteração,
          divulgação ou destruição das informações pessoais.
        </p>
        <h2>Compromisso com a Privacidade e LGPD:</h2>
        <p>
          Nosso aplicativo está em total conformidade com a Lei Geral de
          Proteção de Dados (LGPD - Lei nº 13.709/2018), e respeitamos os
          direitos dos usuários em relação à privacidade e proteção de dados
          pessoais. O NIS armazenado, apenas no celular do usuário, não será
          compartilhado com terceiros ou utilizado para qualquer finalidade além
          da facilitação de realização da consulta no aplicativo. Caso o usuário
          opte por desinstalar o aplicativo, o NIS armazenado será
          automaticamente removido do dispositivo.
        </p>
        <h2>Compartilhamento de Informações:</h2>
        <p>
          Não compartilharemos, venderemos ou alugaremos suas informações
          pessoais a terceiros.
        </p>
        <h2>Filiação, Representação e Origem dos Dados</h2>
        <p>
          Queremos deixar claro que não possuímos nenhum vínculo com a Receita
          Federal do Brasil, a Caixa Econômica Federal (CEF) ou qualquer outra
          instituição de crédito ou entidade relacionada. Além disso, é
          importante destacar que não possuímos qualquer vínculo ou afiliação
          com entidades governamentais e não atuamos como representantes de
          nenhuma delas. Os dados utilizados por este aplicativo são de fontes
          PÚBLICAS e acessíveis por meio de consultas públicas.
        </p>
        <p>
          Essas informações oficiais são obtidas através de APIs abertas ou
          extraídas de bases de Dados Abertos, conforme estabelecido no Decreto
          nº 8.777, de 11 de maio de 2016, do Governo Federal do Brasil. Nosso
          aplicativo tem como objetivo facilitar o acesso a dados públicos de
          forma transparente e confiável, fornecendo aos usuários uma ferramenta
          para consultar informações disponibilizadas pelo governo, sem
          estabelecer qualquer relação direta com as instituições governamentais
          responsáveis por essas informações.
        </p>
        <p>
          Para mais informações:
          <ul>
            <li>https://www.gov.br/mds/pt-br</li>
            <li>http://www.portaltransparencia.gov.br/api-de-dados</li>
          </ul>
        </p>
        <p>
          Reforçamos nosso compromisso com a privacidade e segurança dos
          usuários, garantindo que todas as informações acessadas através do
          aplicativo são de domínio público e estão em conformidade com as
          políticas de acesso a dados governamentais. Caso você tenha alguma
          dúvida ou preocupação sobre a origem ou tratamento dos dados, entre em
          contato conosco por meio do e-mail ctappcore@gmail.com, e teremos
          prazer em fornecer as informações necessárias.
        </p>
        <h2>Provedores de serviço</h2>
        <p>
          Para aprimorar nosso Serviço, contamos com a colaboração de empresas e
          terceiros ("Provedores de Serviço") que nos auxiliam na facilitação do
          aplicativo, na análise do uso do Serviço e na veiculação de anúncios.
          Esses Provedores de Serviço têm acesso apenas aos seus Dados Pessoais
          necessários para executar suas funções e são estritamente proibidos de
          divulgá-los ou utilizá-los para qualquer outra finalidade.
        </p>
        <p>
          Os principais Provedores de Serviço que utilizamos são os seguintes:
        </p>
        <p>
          1. Google Analytics: Utilizamos o Google Analytics, serviço de análise
          da web do Google, para rastrear e relatar o tráfego do nosso Serviço.
          Esses dados são compartilhados com outros serviços do Google, que
          podem contextualizar e personalizar anúncios com base em sua rede de
          publicidade. Para saber mais sobre as práticas de privacidade do
          Google, visite: https://policies.google.com/privacy
        </p>
        <p>
          2. Firebase: Utilizamos o Firebase, serviço de análise fornecido pelo
          Google Inc. para aprimorar nosso aplicativo. Você pode desativar
          certos recursos do Firebase nas configurações do seu dispositivo móvel
          ou seguindo as instruções fornecidas pelo Google em sua Política de
          Privacidade: https://policies.google.com/privacy. Recomendamos que
          você também revise a política do Google para proteger seus dados:
          https://support.google.com/analytics/answer/6004245.
        </p>
        <p>
          3. Google AdMob: Empregamos o serviço AdMob do Google Inc. para
          veicular anúncios no nosso aplicativo. Você pode desativar o serviço
          AdMob seguindo as instruções fornecidas pelo Google:
          https://support.google.com/ads/answer/2662922?hl=pt-BR. Para mais
          informações sobre como o Google utiliza os dados coletados, acesse a
          página "Como o Google usa dados quando você usa o site ou aplicativo
          de nossos parceiros":
          https://policies.google.com/technologies/partner-sites/ ou visite a
          Política de Privacidade do Google:
          https://policies.google.com/privacy.
        </p>

        <h2>Coleta de Informações para Publicidade:</h2>

        <p>
          Em nossa busca contínua para aprimorar a experiência do usuário,
          colaboramos com empresas especializadas em análise para entender como
          nosso aplicativo é utilizado. Esse processo envolve a coleta de dados
          sobre a frequência e a duração de uso, visando compreender melhor as
          necessidades e preferências dos nossos usuários.
        </p>
        <p>
          Além disso, trabalhamos em parceria com anunciantes e redes de
          publicidade terceirizadas para viabilizar a manutenção de nossos
          aplicativos de forma gratuita ou com preços acessíveis. Esses
          parceiros necessitam compreender como você interage com as
          publicidades exibidas, permitindo-nos oferecer conteúdos relevantes e
          adequados aos seus interesses.
        </p>
        <p>
          As informações coletadas pelo aplicativo para fins publicitários
          incluem, mas não se limitam a, um ID de identificação exclusivo do seu
          dispositivo móvel e o número de telefone celular associado a ele.
          Entendemos a importância da proteção da sua privacidade e, portanto,
          utilizamos tecnologia de criptografia avançada para garantir que tais
          informações sejam mantidas anônimas, impedindo qualquer identificação
          pessoal por parte dos terceiros envolvidos no processo.
        </p>

        <p>
          Ressaltamos que os terceiros também podem coletar informações anônimas
          sobre outros aplicativos presentes em seu dispositivo móvel, os sites
          móveis visitados por você e dados de localização não precisos, como o
          CEP. Tais informações são utilizadas com a finalidade de análise e
          para oferecer publicidade direcionada de forma anônima tanto dentro do
          nosso aplicativo quanto em outros espaços digitais.
        </p>
        <p>
          É importante frisar que respeitamos sua privacidade e temos o
          compromisso de assegurar que seus dados sejam tratados de acordo com
          as leis aplicáveis e nossas políticas de privacidade.
        </p>

        <h2>Direitos dos Usuários:</h2>
        <p>
          Os usuários têm o direito de acessar, retificar e excluir seus dados
          pessoais armazenados em nosso aplicativo. Para exercer esses direitos
          ou para obter mais informações sobre nossas práticas de privacidade,
          os usuários podem entrar em contato conosco através do e-mail de
          suporte fornecido.
        </p>
        <h2>Alterações nesta Política de Privacidade:</h2>
        <p>
          Reservamos o direito de atualizar ou modificar esta Política de
          Privacidade a qualquer momento, conforme necessário. Quaisquer
          alterações serão publicadas nesta página. É responsabilidade do
          usuário verificar periodicamente esta Política de Privacidade para se
          manter informado sobre quaisquer atualizações ou modificações. Ao
          utilizar o aplicativo Bolsa Família Consulta, você concorda com esta
          Política de Privacidade e com o processamento das suas informações
          pessoais conforme descrito acima.
        </p>
      </div>
    </>
  );
};

export default PoliticaPrivacidade;
